<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <new @add="addDataSideBar" :isSidebarActive="showNewSidebar"
         @closeSidebar="showNewSidebar= false"/>

    <update @update="updateDateSideBar" :isSidebarActive="showUpdateSidebar" @closeSidebar="showUpdateSidebar= false"
            :data="sidebarData"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="selected" @click="sidebarData = selected,showUpdateSidebar = true">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="typeDocument">Tipo de Documento</vs-th>
        <vs-th sort-key="document">Documento</vs-th>
        <vs-th sort-key="email">Email</vs-th>
        <vs-th sort-key="phone">Celular</vs-th>
        <vs-th sort-key="state">Estado</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.typeDocument }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.document }}</p>
          </vs-td>

          <vs-td :data="tr.email">
            <p class="product-name font-medium truncate">{{ tr.email }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.phone }}</p>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchState(tr)" v-model="tr.state"/>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon title="Editar" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData = tr,showUpdateSidebar = true"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import { db } from '@/firebase/firebaseConfig'
import New from '@/views/clients/New'
import Update from '@/views/clients/Update'

export default {
  name: 'ClientsList',
  components: {
    vSelect,
    New,
    Update
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      initProgress: false,

      // Data Sidebar
      showUpdateSidebar: false,
      showNewSidebar: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      //Get clients
      this.initProgress = true
      const querySnap = await db.collection('clients').orderBy('createdAt', 'desc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          ...doc.data()
        }
        this.list.push(client)
      })
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const _ = require('lodash')
        const list = _.cloneDeep(this.list)
        const headerVal = ['businessName', 'typeDocument', 'document', 'email', 'phone']
        const headerTitle = ['Nombre', 'Tipo de documento', 'Documento', 'Email', 'Celular']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Set state in firestore
     * @param document
     */
    switchState (document) {
      let docRef = db.collection('clients').doc(document.id)
      docRef.update({
        state: document.state
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Cliente',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .product-name {
    max-width: 23rem;
  }
}
</style>
