<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar cliente</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Retainer?-->
        <div>
          <h6>¿Es retenedor?</h6>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="retainer" v-model="document.retainer" vs-value="true">Si</vs-radio>
            </li>
            <li>
              <vs-radio vs-name="retainer" v-model="document.retainer" vs-value="false">No</vs-radio>
            </li>
          </ul>
        </div>
        <!-- Type document -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Tipo de documento*</label>
          <v-select :options="typesDocuments"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.typeDocument" class="mb-4 md:mb-0" name="typeDocument" id="typeDocument"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('typeDocument')">{{ errors.first('typeDocument') }}</span>
        </div>

        <!--Document-->
        <vs-input label="Documento*"
                  v-model.number="document.document"
                  class="w-full mt-5" name="document"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('document')">{{ errors.first('document') }}</span>

        <!--Business name-->
        <vs-input @blur="document.businessName= trimString(document.businessName)" label="Denominación*"
                  v-model="document.businessName"
                  class="w-full mt-5" name="businessName"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('businessName')">{{ errors.first('businessName') }}</span>

        <!--Address-->
        <vs-input @blur="document.address= trimString(document.address)" label="Dirección*"
                  v-model="document.address"
                  class="w-full mt-5" name="address"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('address')">{{ errors.first('address') }}</span>

        <!--Department-->
        <vs-input @blur="document.department= trimString(document.department)" label="Departamento*"
                  v-model="document.department"
                  class="w-full mt-5" name="department"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('department')">{{ errors.first('department') }}</span>

        <!--Province-->
        <vs-input @blur="document.province= trimString(document.province)" label="Provincia*"
                  v-model="document.province"
                  class="w-full mt-5" name="province"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('province')">{{ errors.first('province') }}</span>

        <!--District-->
        <vs-input @blur="document.district= trimString(document.district)" label="Distrito*"
                  v-model="document.district"
                  class="w-full mt-5" name="district"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('district')">{{ errors.first('district') }}</span>

        <!--Ubigeo-->
        <vs-input @blur="document.ubigeo= trimString(document.ubigeo)" label="Ubigeo*"
                  v-model="document.ubigeo"
                  class="w-full mt-5" name="ubigeo"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('ubigeo')">{{ errors.first('ubigeo') }}</span>

        <!--Phone-->
        <vs-input @blur="document.phone= trimString(document.phone)" label="Telefono*"
                  v-model="document.phone"
                  class="w-full mt-5" name="phone"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('phone')">{{ errors.first('phone') }}</span>

        <!--Email-->
        <vs-input @blur="document.email= trimString(document.email)" label="Email*"
                  v-model="document.email"
                  class="w-full mt-5" name="email"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('email')">{{ errors.first('email') }}</span>


        <!-- Segment -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Segmento*</label>
          <v-select :options="segments" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.segment" class="mb-4 md:mb-0" name="segment" v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('segment')">{{ errors.first('segment') }}</span>
        </div>

        <!---Payment method--->
        <div class="mt-5">
          <label class="text-sm opacity-75">Forma de pago*</label>
          <v-select :options="paymentMethods"
                    label="value"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
        </div>
        <!--End-->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import {auth, db, FieldValue} from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      typesDocuments: [
        'DNI',
        'RUC'
      ],
      segments: [
        'Grandes empresas',
        'Medianas empresas',
        'Pequeñas empresas',
        'Microempresas',
        'Gobierno',
        'Educación superior'
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      paymentMethods: [
        {
          id: 7,
          value: 'Crédito a 7 días'
        },
        {
          id: 30,
          value: 'Crédito a 30 días'
        },
        {
          id: 15,
          value: 'Crédito a 15 días'
        },
        {
          id: 20,
          value: 'Crédito a 20 días'
        },
        {
          id: 45,
          value: 'Crédito a 45 días'
        },
        {
          id: 60,
          value: 'Crédito a 60 días'
        },
        {
          id: 90,
          value: 'Crédito a 90 días'
        },
        {
          id: 1,
          value: '50% Adelantado 50% contra entrega'
        },
        {
          id: 30,
          value: '50% Adelantado 50% a 30 dias'
        },
        {
          id: 0,
          value: 'Contado'
        }
      ]
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Update client in firestore
          let obj = {
            ...this.document
          }
          await db.collection('clients').doc(this.document.id).update({
            ...obj,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })
          this.$emit('closeSidebar')
          this.$emit('update', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Cliente',
            text: 'Cliente actualizado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
